
@tailwind base;
@tailwind components;
@tailwind utilities;


/* img, */
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block !important;
}